<fap-header>
    <div class="d-flex filters align-items-center">
        <div class="tree_combo mr-3">
            <fap-tree-combo></fap-tree-combo>
          </div>
      <fap-search-filter
        [isDateFilterEnabled]="true"
        [isDayFilterEnabled]="true"
        [filterLabel]="'Drill'"
        (filter)="drillData($event)"
      ></fap-search-filter>
    </div>
  </fap-header>
<ng-container *ngIf="device">
    <div
        class="bgrow"
        style="
            position: relative;
            width: 100%;
            background: #e0e0e0;
            min-height: calc(100vh - 50px);
            z-index: 0;
        "
    >
        <ngx-masonry [options]="options" [ordered]="false">
            <div
                ngxMasonryItem
                *ngFor="let widget of widgets"
                class="example-box  {{ widget?.type?.uniqueid }} {{
                    widget.sizeSmall
                }} {{ widget.sizeMedium }} {{ widget.sizeLarge }} {{
                    widget.bgColor
                }}"
                style="margin: 5px 2.5px"
            >
                <fap-table-widget
                    *ngIf="widget?.type?.uniqueid === 'table'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [bgColor]="widget.bgColor"
                    [langString]="langString"
                >
                </fap-table-widget>
                <fap-crop-eye-simple-widget
                    *ngIf="widget?.type?.uniqueid === 'cropeyesimple'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%; overflow: hidden"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                >
                </fap-crop-eye-simple-widget>
                <fap-water-content-widget
                    *ngIf="widget?.type?.uniqueid === '*lux5GMLWat'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                >
                </fap-water-content-widget>
                <fap-satellite-widget
                    *ngIf="widget?.type?.uniqueid === '*lux5GSat'"
                    [widget]="widget"
                    (emitShow)="showError()"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                >
                </fap-satellite-widget>

                <fap-image-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'image'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                    style="display: block; width: 100%"
                ></fap-image-widget>

                <fap-overview-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'overview'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                    style="display: block; width: 100%"
                ></fap-overview-widget>

                <fap-soil-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'soil'"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [widget]="widget"
                    [langString]="langString"
                    style="display: block; width: 100%"
                >
                </fap-soil-widget>

                <fap-image-slider-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'cropeye'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                    style="display: block; width: 100%; overflow: hidden"
                ></fap-image-slider-widget>
                <fap-alert-widget
                    (emitShow)="showError()"
                    *ngIf="
                        widget?.type?.uniqueid === 'fungal_alert' ||
                        widget?.type?.uniqueid === 'pest_alert' ||
                        widget?.type?.uniqueid === 'pathogen_alert'
                    "
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [crops]="crops"
                    [langString]="langString"
                ></fap-alert-widget>

                <fap-weed-control-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'herbicide'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [crops]="crops"
                    [langString]="langString"
                >
                </fap-weed-control-widget>

                <fap-chart-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'graph'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></fap-chart-widget>

                <graph-range-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'graph_range'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></graph-range-widget>

                <graph-percentage-widget
                    (emitShow)="showError()"
                    *ngIf="widget?.type?.uniqueid === 'graph_percentage'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></graph-percentage-widget>

                <fap-carousel-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'images_list'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></fap-carousel-widget>

                <fap-bin-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'bins'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [sensors]="sensors"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></fap-bin-widget>

                <fap-sensor-value-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'sensor_value'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                    style="display: block; width: 100%"
                ></fap-sensor-value-widget>

                <fap-overview-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'overview'"
                    [widget]="widget"
                    [notes]="notes"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [devices]="devices"
                    [langString]="langString"
                    style="display: block; width: 100%"
                ></fap-overview-widget>

                <fap-activities-list-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'activities'"
                    [widget]="widget"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [activities]="activities"
                    [activityTypes]="activityTypes"
                    [devices]="devices"
                    (emitScroll)="scrollActivities()"
                    (refreshActivitiesList)="getActivities()"
                    [langString]="langString"
                    style="display: block; width: 100%"
                ></fap-activities-list-widget>

                <fap-pool-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'pool'"
                    [widget]="widget"
                    [objects]="objects"
                    [sensors]="sensors"
                    [unitTypes]="unitTypes"
                    [editValue]="false"
                    [devices]="devices"
                    [langString]="langString"
                    style="display: block; width: 100%"
                ></fap-pool-widget>

                <fap-weather-forecast-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'weather_forecast'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [sensors]="sensors"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></fap-weather-forecast-widget>

                <fap-map-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'map'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [sensors]="sensors"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></fap-map-widget>

                <fap-device-info-widget
                    (emitShow)="showModal(widget)"
                    *ngIf="widget?.type?.uniqueid === 'device_info'"
                    [widget]="widget"
                    style="display: block; width: 100%"
                    [objects]="objects"
                    [unitTypes]="unitTypes"
                    [langString]="langString"
                ></fap-device-info-widget>
            </div>
        </ngx-masonry>
    </div>
</ng-container>
